import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from 'angularfire2/auth';
import { HistorySong } from '../shared/models/program.model';
import * as firebase from 'firebase';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  authState: any = null;

  constructor(
    public afAuth: AngularFireAuth,
    private firestore: AngularFirestore
    ) {
    this.afAuth.authState.subscribe((auth) => {
      this.authState = auth;
    });
  }

  get authenticated(): boolean {
    return this.authState !== null;
  }

    // Returns
  get currentUserObservable(): any {
    return this.afAuth.authState;
  }

  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : '';
  }


  loginUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.auth.signInWithEmailAndPassword(newEmail, newPassword);
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<void> {
    return this.afAuth.auth.signOut();
  }

  signupUser(newEmail: string, newPassword: string): Promise<any> {
    return this.afAuth.auth.createUserWithEmailAndPassword(newEmail, newPassword);
  }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        const user = {firstname: '', lastname: '', email: ''};
        user.firstname = res.user.displayName.slice(0, res.user.displayName.indexOf(' '));
        user.lastname = res.user.displayName.slice(res.user.displayName.indexOf(' ') + 1, res.user.displayName.length);
        user.email = res.user.email;
        this.signupdbUser(res.user.uid, user);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
 }


  signupdbUser(id, user) {
    return this.firestore.collection('users').doc(id).set({lastname: user.lastname, firstname: user.firstname, email: user.email, type: 'public'});
  }

  updateUser(id, user) {
    return this.firestore.collection('users').doc(id).set({lastname: user.lastname,
      firstname: user.firstname,
      email: user.email,
      postalCode: user.postalCode,
      city: user.city
    });
  }

  getSongHistory(id_user) {
    return this.firestore.collection('users').doc(id_user).collection('historySong').snapshotChanges();
  }

  setSongHistory(id_user, song: HistorySong) {
    return this.firestore.collection('users').doc(id_user).collection('historySong').add({
      id: song.id,
      name: song.name,
      timeInSecond: song.timeInSecond,
      urlFile: song.urlFile,
      sessionName: song.sessionName});
  }

  setSessionHistory(sessionHistory) {
    console.log('session hiiiistory====>', sessionHistory);
    return this.firestore.collection('sessionHistory').add({
      createdAt: sessionHistory.createdAt,
      endDiagnostic: sessionHistory.endDiagnostic,
      endEmotion: sessionHistory.endEmotion,
      isEmotion: sessionHistory.isEmotion,
      rating: sessionHistory.rating,
      score: sessionHistory.score,
      session: sessionHistory.session,
      sessionName: sessionHistory.sessionName,
      startDiagnostic: sessionHistory.startDiagnostic,
      startEmotion: sessionHistory.startEmotion,
      timeElapsed: sessionHistory.timeElapsed,
      user: sessionHistory.user
    });
  }

  getSessionHistory() {
    return this.firestore.collection('sessionHistory', ref => ref.where('user', '==', this.currentUserId)).snapshotChanges();
  }

  getPropositionSongs(type) {
    return this.firestore.collection('proposition').doc(type).collection('songs').snapshotChanges();
  }


  getSessions() {
    return this.firestore.collection('session').snapshotChanges();
  }

  getPosts() {
    return this.firestore.collection('posts').snapshotChanges();
  }

  getOnePosts(idPost) {
    return this.firestore.collection('posts').doc(idPost).snapshotChanges();
  }

  getPostSections(postId) {
    return this.firestore.collection('posts').doc(postId).collection('sections').snapshotChanges();
  }

  getOneSession(id) {
    return this.firestore.collection('session').doc(id).snapshotChanges();
  }

  getSongs(idSession) {
    return this.firestore.collection('session').doc(idSession).collection('songs').snapshotChanges();
  }

  getOneSong(idSession, idSong) {
    return this.firestore.collection('session').doc(idSession).collection('songs').doc(idSong).snapshotChanges();
  }

  getUser(uid) {
    return this.firestore.collection('users').doc(uid).snapshotChanges();
  }

  updatePassword(password) {
    return this.afAuth.auth.currentUser.updatePassword(password);
  }

  forgetPassword(email) {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
}
