import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Post, Section } from 'src/app/shared/models/post.model';
import { PostsService } from 'src/app/services/posts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent implements OnInit {

  sections: Section[] = [];
  post: Post;
  file: string;
  date: any;

  constructor(
    private navController: NavController,
    private postsService: PostsService,
    private location: Location
    ) {

      this.postsService.currentSections.subscribe( sections => {
        this.sections = sections;
      });
      this.postsService.getSectionsForCurrentPost();
  }

  ngOnInit() {
    this.post = this.postsService.currentSelectedPost;
    this.date = this.post.createdAt.toDate();
    this.chooseFile();
  }

  setSections() {

  }

  onClick() {
    this.navController.pop();
  }

  chooseFile() {
    if (this.post.type === 'picture') {
      this.file = this.post.urlPicture;
    } else if (this.post.type === 'video') {
      this.file = this.post.urlVideo;
    } else {
      this.file = 'assets/test_card.jpg';
    }
  }

  onClose() {
    this.location.back();

  }

}
