import { Timestamp } from 'rxjs';

export class Post {
    id: string;
    type: string;
    urlPicture: string;
    urlVideo: string;
    isPublic: boolean;
    createdAt: any;
    targets: Array<string>;
}

export class Section {

    id: string;
    postition: number;
    text: string;
}