import { Injectable } from '@angular/core';
import { Post, Section } from '../shared/models/post.model';
import { DataService } from './data.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(
    private dataService: DataService
  ) { }

  private _posts: BehaviorSubject<Post[]> = new BehaviorSubject<Post[]>([]);
  private _currentSections: BehaviorSubject<Section[]> = new BehaviorSubject<Section[]>([]);

  get posts() {
    return this._posts.asObservable();
  }

  get currentSections() {
    return this._currentSections.asObservable();
  }

  currentSelectedPost: Post;

  getPosts() {
    this.dataService.getPosts().subscribe(data => {
     const posts = data.map(e => {

        const post = new Post();

        post.id = e.payload.doc.id;

        Object.assign(post, e.payload.doc.data());

        return post;
      });

     console.log(posts);
     this._posts.next(posts);
    });
  }

  getSectionsForCurrentPost() {
    if (this.currentSelectedPost == null) {
      return;
    }

    this.dataService.getPostSections(this.currentSelectedPost.id).subscribe(data => {
      const sections = data.map(e => {

        const section = new Section();

        Object.assign(section, e.payload.doc.data());

        return section;
      });

      this._currentSections.next(sections);
    });
  }

  setSelectedPost(post: Post) {
    this.currentSelectedPost = post;
  }
}
