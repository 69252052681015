import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ArticleComponent } from './components/article/article.component';

const routes: Routes = [
  { path: '', redirectTo: 'navigation', pathMatch: 'full' },
  // { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  // { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
  // { path: 'program', loadChildren: './program/program.module#ProgramPageModule' },
  { path: 'navigation', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'cool-down/:id', loadChildren: './cool-down/cool-down.module#CoolDownPageModule' },
  { path: 'pending-page/:id', loadChildren: './pending-page/pending-page.module#PendingPagePageModule' },
  { path: 'infos/:id', component: ArticleComponent},
  { path: 'finish-page', loadChildren: './finish-page/finish-page.module#FinishPagePageModule' },
  { path: 'music-player/:id', loadChildren: './music-player/music-player.module#MusicPlayerPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'diagnostic/:position/:id', loadChildren: './diagnostic/diagnostic.module#DiagnosticPageModule' },
  { path: 'sign-in', loadChildren: './sign-in/sign-in.module#SignInPageModule' },
  { path: 'sign-up', loadChildren: './sign-up/sign-up.module#SignUpPageModule' },
  { path: 'splash-screen', loadChildren: './splash-screen/splash-screen.module#SplashScreenPageModule' }
  // { path: 'info', loadChildren: './info/info.module#InfoPageModule' },
  // { path: 'history', loadChildren: './history/history.module#HistoryPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
